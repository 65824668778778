<template>
	<div class="newsEdit">
		<a-row type="flex" align="middle" class="bt16">
			<a-col>
				<span class="red">*</span>
				标题：</a-col
			>
			<a-col
				><a-input placeholder="请输入标题" v-model="title"></a-input
			></a-col>
		</a-row>
		<a-row type="flex" class="bt16">
			<a-col> <span class="red">*</span>内容：</a-col>
			<a-col :span="22">
				<div id="wangeditor"></div>
			</a-col>
		</a-row>
		<a-row type="flex" align="middle" class="bt16">
			<a-col><span class="red">*</span>封面：</a-col>
			<a-col>
				<a-upload
					name="avatar"
					list-type="picture-card"
					class="avatar-uploader"
					:show-upload-list="false"
					:customRequest="customRequest"
					:before-upload="beforeUpload"
					@change="handleChange"
				>
					<img v-if="imageUrl" :src="imageUrl" alt="avatar" />
					<div v-else>
						<a-icon :type="loading ? 'loading' : 'plus'" />
						<div class="ant-upload-text">Upload</div>
					</div>
				</a-upload>
			</a-col>
		</a-row>
		<a-row type="flex" align="middle" class="bt16">
			<a-col><span class="red">*</span>日期：</a-col>
			<a-col>
				<a-date-picker v-model="time" />
			</a-col>
		</a-row>
		<a-row type="flex" align="middle" class="bt16">
			<a-col><span class="red">*</span>标签：</a-col>
			<a-col>
				<a-select
					mode="multiple"
					v-model="tag"
					style="width: 200px"
					placeholder="请选择文章标签"
				>
					<a-select-option
						v-for="(item, index) in tagList"
						:key="index"
						:value="item.id"
					>
						{{ item.tagName }}
					</a-select-option>
				</a-select>
			</a-col>
		</a-row>
		<a-row>
			<a-col span="24" style="text-align: center">
				<a-button style="margin-right: 8px" @click="$router.go(-1)">
					取消
				</a-button>
				<a-button type="primary" @click="submit">确认</a-button>
			</a-col>
		</a-row>
	</div>
</template>

<script>
	import E from "wangeditor";
	export default {
		name: "news-edit",
		data() {
			return {
				detail: {},
				// 标题
				title: "",
				// 富文本
				editor: null,
				// 图片
				file: null,
				loading: false,
				imageUrl: "",
				// 日期
				time: null,
				// 标签
				tag: [],
				tagList: []
			};
		},
		mounted() {
			this.initEditor();
			this.getTagList();
		},
		methods: {
			// 获取所有标签
			getTagList() {
				this.$axios.post("/news/tag").then(res => {
					this.tagList = res;
					this.$route.query.id && this.getDetail();
				});
			},
			// 获取新闻详情
			getDetail() {
				this.$axios
					.get("/news/detail", {
						params: { id: this.$route.query.id }
					})
					.then(res => {
						this.detail = res;
						this.title = this.detail.title;
						this.editor.txt.html(this.detail.content);
						this.imageUrl = this.detail.coverImg;
						this.time = this.$moment(this.detail.createTime);
						this.detail.tags.map(item => {
							this.tag.push(item.id);
						});
					});
			},
			// 初始化editor
			initEditor() {
				this.editor = new E("#wangeditor");
				this.editor.config.height = 500;
				this.editor.config.excludeMenus = [
					"emoticon",
					"video",
					"todo",
					"code"
				];
				this.editor.config.pasteFilterStyle = true;
				this.editor.config.pasteIgnoreImg = true;
				this.editor.config.showFullScreen = false;
				this.editor.config.uploadImgServer = true;
				this.editor.config.uploadImgMaxLength = 1;
				this.editor.config.uploadImgMaxSize = 5 * 1024 * 1024;
				let _this = this;
				this.editor.config.customUploadImg = function (
					resultFiles,
					insertImgFn
				) {
					// resultFiles 是 input 中选中的文件列表
					// insertImgFn 是获取图片 url 后，插入到编辑器的方法
					let formdata = new FormData();
					formdata.append("file", resultFiles[0]);
					_this.$axios
						.post("/news/cover/upload", formdata, {
							headers: {
								"content-type": "multipart/form-data"
							}
						})
						.then(res => {
							insertImgFn(res);
						});
				};
				this.editor.create();
			},
			// 上传
			handleChange(info) {
				if (info.file.status === "uploading") {
					this.loading = true;
					return;
				}
				if (info.file.status === "done") {
					this.loading = false;
				}
			},
			beforeUpload(file) {
				const isJpgOrPng =
					file.type === "image/jpeg" || file.type === "image/png";
				if (!isJpgOrPng) {
					this.$message.error("You can only upload JPG or PNG file!");
				}
				const isLtM = file.size / 1024 / 1024 < 1;
				if (!isLtM) {
					this.$message.error("Image must smaller than 1MB!");
				}
				if (isJpgOrPng && isLtM) {
					this.file = file;
				}
				return isJpgOrPng && isLtM;
			},
			customRequest() {
				let formdata = new FormData();
				formdata.append("file", this.file);
				this.$axios
					.post("/news/cover/upload", formdata, {
						headers: {
							"content-type": "multipart/form-data"
						}
					})
					.then(res => {
						this.imageUrl = res;
						this.loading = false;
					});
			},
			// 提交
			submit() {
				let body = {
					content: this.editor.txt.html(),
					coverImg: this.imageUrl,
					createTime:
						this.time &&
						this.$moment(this.time).format("YYYY-MM-DD") +
							" 00:00:00",
					tagId: this.tag,
					title: this.title
				};
				if (this.$route.query.id) body.id = this.$route.query.id + "";
				try {
					for (let item of Object.values(body)) {
						if (!item || !item.length)
							throw new Error("请检查参数是否填写正确");
					}
					if (Object.values(body))
						if (!body.id) {
							this.addNews(body);
						} else {
							this.editNews(body);
						}
				} catch (error) {
					this.$message.info("请检查参数是否填写正确");
				}
			},
			addNews(obj) {
				this.$axios.post("/news/create", obj).then(() => {
					this.$message.success("创建成功");
					this.$router.go(-1);
				});
			},
			editNews(obj) {
				this.$axios.put("/news/edit", obj).then(() => {
					this.$message.success("修改成功");
					this.$router.go(-1);
				});
			}
		}
	};
</script>

<style lang="scss">
	.ant-calendar-picker-container {
		z-index: 999999;
	}
	.newsEdit {
		.red {
			color: #ff0000;
			font-size: 12px;
		}
		.bt16 {
			margin-bottom: 16px;
		}
		#wangeditor {
			resize: vertical;
		}
		.avatar-uploader > .ant-upload {
			width: 128px;
			height: 128px;
		}
		.ant-upload-select-picture-card i {
			font-size: 32px;
			color: #999;
		}

		.ant-upload-select-picture-card .ant-upload-text {
			margin-top: 8px;
			color: #666;
		}
		.ant-upload {
			img {
				width: 100%;
			}
		}
	}
</style>
